import React, { Component } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import Modal from "../../../common/modal/modal";
import { Col, Row, FormGroup } from "react-bootstrap";
import Config from "../../../../config";
import DateBoxPicker from "../../../common/form-material/date-box";
import Attachments from "../../../common/attachments/attachments";
import { Combo } from "../../../common/form-material";
import ButtonGeneral from "../../../common/button/button-general";
import { withStyles, IconButton, TextField } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W09F2021Actions from "../../../../redux/W0X/W09F2021/W09F2021_actions";
import moment from "moment";
import _ from "lodash";
import Api from "../../../../services/api";
import CDN from "../../../CDN";

const styles = (theme) => ({
    labelTitle: {
        "& > span": {
            paddingLeft: 5,
        },
    },
    colInfo: {
        display: "flex",
        marginBottom: 20,
        "& > label:first-child": {
            width: "40%",
        },
        "& .MuiFormLabel-root.note-title": {
            width: "19.2%",
        },
        "& .MuiFormLabel-root.note-content": {
            width: "80%",
        },
        [theme.breakpoints.down("sm")]: {
            justifyContent: "space-between",
            "& .MuiFormLabel-root.note-content": {
                width: "52%",
            },
            "& .MuiFormLabel-root.note-title": {
                width: "45%",
            },
        },
    },
    removeUnderLine: {
        "& .MuiInput-underline:before": {
            content: "none",
        },
        "& .MuiInput-underline:after": {
            content: "none",
        },
        "& .MuiInputBase-input": {
            cursor: "default",
        },
    },
});
class W09F2021 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataOldAttachments: [],
            uploading: false,
            isSaving: false,
            employeeLoading: false,
            disableCboEmployee: false,
            iPermissionW09F2022: 0,
            dataForm: {
                employee: null,
                AbsentDateFrom: null,
                AbsentDateTo: null,
                PlanChildDate: null,
                ExpectWorkDay: null,
                TotalDay: 180,
                NoteU: "",
            },
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
        };
        this.attachments = [];
        this.deletedFile = [];
        this.defaultParams = {
            FormID: "W09F2021",
            Language: Config.language || "84",
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50,
        };
    }

    handleChange = (key, e) => {
        const { dataForm } = this.state;
        const arrKeyDependents = ["AbsentDateFrom", "TotalDay"];
        const value = _.get(e, "value", _.get(e, "target.value", ""));
        let objResultAbsDateTo = {};
        if (arrKeyDependents.includes(key)) {
            const getIndex = _.indexOf(arrKeyDependents, key);
            const valAbsDateFrom = !getIndex ? value : _.get(dataForm, "AbsentDateFrom", "");
            const valTtDay = getIndex ? value : _.get(dataForm, "TotalDay", 0);
            if (valAbsDateFrom && valTtDay) {
                let resultAbsDateTo = moment(new Date(valAbsDateFrom)).add(valTtDay, "day").subtract(1, "day").format("YYYY-MM-DD");
                objResultAbsDateTo = { AbsentDateTo: resultAbsDateTo };
            } else {
                objResultAbsDateTo = { AbsentDateTo: "" };
            }
        }
        this.setState({ dataForm: { ...dataForm, [key]: value, ...objResultAbsDateTo } });
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1],
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;

        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : "",
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    loadPermission = async () => {
        await this.props.generalActions.getPermission(
            "W09F2022",
            (isPer) => {
                let user = null;
                let disableCboEmployee = false;
                if (isPer <= 2) {
                    disableCboEmployee = true;
                    user = Config.getUser({ EmployeeID: Config.getHREmployeeID() });
                }
                this.setState({ disableCboEmployee, dataForm: { ...this.state.dataForm, employee: user } });
            },
            true,
        );
    };

    loadCboEmployees = (isReset) => {
        const param = {
            HostID: "",
            Type: "EmployeeID",
            ...this.defaultParams,
            FormID: "W09F2020",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch,
        };
        this.setState({ employeeLoading: true });
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({ employeeLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const { dataCboEmployees } = this.state;
                const _d = data && data.rows ? data.rows : [];
                const total = data && data.total ? data.total : 0;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? _d : dataCboEmployees.rows.concat(_d),
                        total: total,
                    },
                });
            }
        });
    };

    loadAttachments = () => {
        const { data } = this.props;
        const param = {
            KeyID: _.get(data, "MaternityTransID", ""),
        };
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({ dataOldAttachments: data });
            }
            this.setState({
                loading: false,
            });
        });
    };

    loadFormData = () => {
        const { Language } = this.defaultParams;
        const { dataForm } = this.state;
        const MaternityTransID = _.get(this.props.data, "MaternityTransID", "");
        const params = {
            Language,
            DivisionID: Config.getDivisionID(),
            MaternityTransID,
        };
        this.props.w09f2021Actions.getForm(params, (err, data) => {
            if (err) {
                Config.popup.show("INFO", _.get(err, "message", "") || Config.lang("DHR_Loi_chua_xac_dinh"));
                return;
            }
            if (data) {
                const EmployeeID = _.get(data, "[0]EmployeeID", "");
                const user = Config.getUser({ EmployeeID: EmployeeID });
                this.setState({
                    dataForm: {
                        ...dataForm,
                        ..._.get(data, "[0]", _.get(data, "rows[0]", {})),
                        employee: user,
                    },
                });
            }
        });
    };

    onUploading = (value) => {
        this.setState({ uploading: value });
    };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        this.attachments.length ? this.setState({ hasAttachment: true }) : this.setState({ hasAttachment: false });
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState((prevState) => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                }),
            }));
        }
    };

    onStore = async () => {
        const { mode, data, onClose } = this.props;
        const { dataForm } = this.state;
        const arrAttachments = this._getAttachments();
        const params = {
            MaternityTransID: _.get(data, "MaternityTransID", ""),
            TranMonth: Config.getHRTransMonth(),
            TranYear: Config.getHRTransYear(),
            DivisionID: Config.getDivisionID(),
            EmployeeID: _.get(dataForm, "employee.EmployeeID", _.get(data, "EmployeeID", "")),
            ..._.omit(dataForm, ["employee","TotalDay"]),
            TotalMonth: dataForm.TotalDay,
            arrAttachment: JSON.stringify(arrAttachments),
        };
        this.setState({ isSaving: true });
        const res = await Api.put(`/api/w09f2021/${mode}`, params);
        this.setState({ isSaving: false });
        const messageErr = _.get(res, "message", "");
        const resData = _.get(res, "data", null);
        const status = !_.get(resData, "Status", 1);
        const messageData = _.get(resData, "Message", "");
        if (messageErr) return Config.popup.show("INFO", messageErr, Config.lang("DHR_Loi_chua_xac_dinh"));
        if (status) {
            Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 2000);
            this._removeCDN();
            onClose("showW09F2021", true);
            return;
        }
        return Config.popup.show("INFO", messageData, Config.lang("DHR_Loi_chua_xac_dinh"));
    };

    convertDate = (date) => {
        if (!moment(date).isValid() || !date) return "";
        return moment.utc(date).format("DD/MM/YYYY");
    };

    getChildPolicyName = (childPolicyID) => {
        switch (childPolicyID) {
            case "LATE":
                return Config.lang("DHR_Di_tre");
            case "EARLY":
                return Config.lang("DHR_Ve_som");
            case "OUTEARLY":
                return Config.lang("DHR_Ra_som");
            case "INLATE":
                return Config.lang("DHR_Vao_tre");
            case "LEAVE":
                return Config.lang("DHR_Quy_doi_phep");
            default:
                break;
        }
    };

    render() {
        const { classes, getForm, data, mode } = this.props;
        const { dataForm, dataOldAttachments, uploading, isSaving, employeeLoading, dataCboEmployees, disableCboEmployee } = this.state;
        const dataAfterMaternity = mode === "edit" ? _.get(getForm, "[0]", "") : {};
        const appStatusID = _.get(data, "AppStatusID", _.get(getForm, "AppStatusID", 1));
        const isRegisterType = _.get(data, "IsRegisterType", _.get(getForm, "IsRegisterType", 0));
        const checkRequiredFields = Boolean(dataForm.AbsentDateFrom && dataForm.TotalDay && !_.isEmpty(dataForm.employee));
        const allowSave = (mode === "add" || (mode === "edit" && Number(appStatusID) === 0)) && checkRequiredFields && isRegisterType === 0;
        const visibleGroup = dataAfterMaternity["ActualWorkDay"] && mode === "edit";
        return (
            <>
                <Modal.Content>
                    <FormGroup>
                        <Row>
                            <Col md={12}>
                                <Combo.Employees
                                    dataSource={dataCboEmployees}
                                    acceptCustomValue={true}
                                    displayExpr={"EmployeeName"}
                                    keyExpr={"EmployeeID"}
                                    valueExpr={"EmployeeID"}
                                    subTextExpr={"EmployeeID"}
                                    value={dataForm.employee}
                                    FormID={"W09F2020"}
                                    stylingMode={"underlined"}
                                    shrink={true}
                                    disabled={isSaving || mode === "edit" || disableCboEmployee}
                                    loading={employeeLoading}
                                    label={Config.lang("DHR_Nhan_vien")}
                                    dropdownProps={{
                                        readOnly: false,
                                    }}
                                    onValueChanged={(e) => this.handleChange("employee", e)}
                                    required={true}
                                    onInputChanged={(e) => {
                                        this.filterCboEmployees.skip = 0;
                                        this.filterCboEmployees.strSearch = e.target.value;
                                        this.loadCboEmployees(true);
                                    }}
                                    onHoverAvatar={() => false}
                                    onLoadMore={(e) => {
                                        this.filterCboEmployees.skip = e.skip;
                                        this.filterCboEmployees.limit = e.limit;
                                        this.loadCboEmployees();
                                    }}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={12}>
                                <label className={classes.labelTitle + " MuiFormLabel-root"}>
                                    {Config.lang("DHR_Thoi_gian")}
                                    <span className={"MuiFormLabel-asterisk MuiInputLabel-asterisk"}>*</span>
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} xs={12}>
                                <DateBoxPicker
                                    placeholder={Config.lang("DD/MM/YYYY")}
                                    height={28}
                                    useMaskBehavior={true}
                                    label={Config.lang("DHR_Tu")}
                                    width={"100%"}
                                    stylingMode={"underlined"}
                                    shrink={true}
                                    InputLabelProps={{ style: { marginTop: "-7px" } }}
                                    value={dataForm.AbsentDateFrom}
                                    onValueChanged={(e) => this.handleChange("AbsentDateFrom", e)}
                                    margin={"normal"}
                                    disabled={isSaving}
                                />
                            </Col>
                            <Col md={4} xs={12}>
                                <DateBoxPicker
                                    placeholder={Config.lang("DD/MM/YYYY")}
                                    height={28}
                                    useMaskBehavior={true}
                                    label={Config.lang("DHR_Den")}
                                    width={"100%"}
                                    stylingMode={"underlined"}
                                    shrink={true}
                                    InputLabelProps={{ style: { marginTop: "-7px" } }}
                                    value={dataForm.AbsentDateTo}
                                    margin={"normal"}
                                    dateBoxProps={{
                                        readOnly: true,
                                    }}
                                    disabled={isSaving}
                                />
                            </Col>
                            <Col md={2} xs={12}>
                                <TextField
                                    label={Config.lang("DHR_So_ngay")}
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="number"
                                    disabled={isSaving}
                                    value={dataForm.TotalDay}
                                    margin={"normal"}
                                    onChange={(e) => this.handleChange("TotalDay", e)}
                                    required={true}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={6}>
                                <DateBoxPicker
                                    placeholder={Config.lang("DD/MM/YYYY")}
                                    height={28}
                                    useMaskBehavior={true}
                                    label={Config.lang("DHR_Ngay_du_sinh")}
                                    width={"100%"}
                                    stylingMode={"underlined"}
                                    shrink={true}
                                    InputLabelProps={{ style: { marginTop: "-7px" } }}
                                    value={dataForm.PlanChildDate}
                                    onValueChanged={(e) => this.handleChange("PlanChildDate", e)}
                                    margin={"normal"}
                                    disabled={isSaving}
                                />
                            </Col>
                            <Col md={6}>
                                <DateBoxPicker
                                    placeholder={Config.lang("DD/MM/YYYY")}
                                    height={28}
                                    useMaskBehavior={true}
                                    label={Config.lang("DHR_Ngay_di_lam_du_kien")}
                                    width={"100%"}
                                    stylingMode={"underlined"}
                                    shrink={true}
                                    InputLabelProps={{ style: { marginTop: "-7px" } }}
                                    value={dataForm.ExpectWorkDay}
                                    onValueChanged={(e) => this.handleChange("ExpectWorkDay", e)}
                                    margin={"normal"}
                                    disabled={isSaving}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup style={{ marginBottom: 34 }}>
                        <Row>
                            <Col md={12}>
                                <TextField
                                    multiline={true}
                                    label={Config.lang("DHR_Ghi_chu")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.NoteU}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={isSaving}
                                    onChange={(e) => this.handleChange("NoteU", e)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                    </FormGroup>

                    {Boolean(visibleGroup) && (
                        <FormGroup style={{ marginBottom: 34 }}>
                            <FormGroup>
                                <Row>
                                    <Col md={12}>
                                        <label className={classes.labelTitle + " MuiFormLabel-root"}>
                                            {Config.lang("DHR_Sau_thai_san:")}
                                        </label>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <Row>
                                <Col xs={12} md={6}>
                                    <div className={classes.colInfo}>
                                        <label className={"MuiFormLabel-root"}>{Config.lang("DHR_Ngay_di_lam_thuc_te")}</label>
                                        <label style={{ fontWeight: "bold" }} className={"MuiFormLabel-root"}>
                                            {this.convertDate(dataAfterMaternity["ActualWorkDay"]) || ""}
                                        </label>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className={classes.colInfo}>
                                        <label className={"MuiFormLabel-root"}>{Config.lang("DHR_Ngay_sinh_con")}</label>
                                        <label style={{ fontWeight: "bold" }} className={"MuiFormLabel-root"}>
                                            {this.convertDate(dataAfterMaternity["ChildBirthDate"]) || ""}
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <div className={classes.colInfo}>
                                        <label className={"MuiFormLabel-root"}>{Config.lang("DHR_Che_do_con_nho")}</label>
                                        <label style={{ fontWeight: "bold" }} className={"MuiFormLabel-root"}>
                                            {this.getChildPolicyName(dataAfterMaternity["GetChildPolicyType"]) || ""}
                                        </label>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className={classes.colInfo}>
                                        <label className={"MuiFormLabel-root"}>{Config.lang("DHR_Ngay_ket_thuc_che_do")}</label>
                                        <label style={{ fontWeight: "bold" }} className={"MuiFormLabel-root"}>
                                            {this.convertDate(dataAfterMaternity["MaternityEndDate"]) || ""}
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <div className={classes.colInfo}>
                                        <label className={"MuiFormLabel-root note-title"}>{Config.lang("DHR_Ghi_chu_sau_thai_san")}</label>
                                        <label
                                            className={"MuiFormLabel-root note-content"}
                                            style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}
                                        >
                                            {dataAfterMaternity["NoteAfterOff"] || ""}
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                        </FormGroup>
                    )}
                    <FormGroup style={{ marginBottom: 34 }}>
                        <div className={classes.asterisk}>
                            <Attachments
                                isAttachInfo
                                ref={(ref) => (this.attRef = ref)}
                                showButton={true}
                                files={dataOldAttachments}
                                disabled={mode === "view"}
                                maxLength={5}
                                uploading={uploading}
                                onUploading={this.onUploading}
                                onChanged={this.onChangeAttachments}
                            />
                        </div>
                    </FormGroup>
                </Modal.Content>
                <Modal.Actions style={{ justifyContent: "space-between" }}>
                    <IconButton aria-label="view" disabled={false} onClick={this.onAttachment}>
                        <AttachFileIcon />
                    </IconButton>
                    <ButtonGeneral
                        name={Config.lang("DHR_Luu")}
                        typeButton={"save"}
                        disabled={!Boolean(allowSave) || isSaving}
                        style={{ textTransform: "uppercase" }}
                        size={"large"}
                        onClick={this.onStore}
                    />
                </Modal.Actions>
            </>
        );
    }

    componentDidMount = () => {
        const { mode } = this.props;
        this.loadPermission();
        this.loadCboEmployees();
        if (mode === "edit" || mode === "view") {
            this.loadFormData();
            this.loadAttachments();
        }
    };
}

export default compose(
    connect(
        (state) => ({
            getCboEmployees: state.general.getCboEmployees,
            getForm: state.W09F2021.getForm,
        }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w09f2021Actions: bindActionCreators(W09F2021Actions, dispatch),
        }),
    ),
    withStyles(styles, { withTheme: true }),
)(W09F2021);

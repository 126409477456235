import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { Row, Col, FormGroup, Image } from "react-bootstrap";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import LabelText from "../../../common/label-text/label-text";
import ButtonGeneral from "../../../common/button/button-general";
import Filter from "../../../filter/filter";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import Approvals from "../../../approvals/approvals";

import InlineSVG from "react-inlinesvg";
import { Input, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from "@material-ui/core";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import _ from "lodash";
import DateBoxPicker from "../../../common/form-material/date-box";
import { Combo } from "../../../common/form-material";

const styles = {
    labelEmployee: {
        "& > label": {
            fontSize: "1.1rem !important",
            fontWeight: "bold !important",
        },
    },
    expansionSummary: {
        "&.MuiExpansionPanelSummary-root.Mui-expanded": {
            minHeight: 48,
        },
        padding: 0,
        height: 0,
    },
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: "50%",
        border: "1px solid #ddd",
        overflow: "hidden",
        marginRight: 8,
    },
    imgAvatar: {
        width: "100%",
        height: "auto",
        objectFit: "contain",
    },
    txtNote: {
        position: "absolute",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontStyle: "italic",
        overflow: "hidden",
        color: "grey",
        fontSize: 14,
        top: "80%",
        width: "100%",
    },
    labelTextDate: {
        fontSize: "1rem",
        fontWeight: 200,
        color: "rgba(0, 0, 0, 0.54)",
    },
    divText: {
        paddingLeft: 5,
        // fontSize: "14px",
        fontWeight: 500,
        color: "#4D4844",
    },
};

class W09F2023 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            gridLoading: false,
            expandedMaternity: false,
            dataCboEmployees: { total: 0, rows: [] },
            dataCboProjects: { total: 0, rows: [] },
            cboEmployeeLoading: false,
            cboProjectLoading: false,
            dataForm: {},
            VoucherID: "",
            dateStart: {
                DateFrom: null,
                DateTo: null,
            },
            dateEnd: {
                DateFrom2: null,
                DateTo2: null,
            },
            
        };
        this.defaultParams = {
            FormID: "W09F2023",
            Language: Config.language || "84",
            DivisionID: Config.getDivisionID(),
        };
        this.filter = {
            DepartmentID: "",
            ApprovalStatus: "",
            IsRegisterType: null,
            employee: null,
            project: null,
        };

        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50,
        };
        this.filterCboProjects = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50,
        };
    }

    loadFormInfo = (MaternityTransID) => {
        const params = {
            ...this.defaultParams,
            VoucherID: MaternityTransID,
        };
        this.setState({ loading: true });
        this.props.approvalActions.getFormInfo(params, (err, data) => {
            this.setState({ loading: false });
            if (err) return Config.popup.show("INFO", _.get(err, "message", Config.lang("DHR_Loi_chua_xac_dinh")));
            if (data) {
                this.setState({ dataForm: data });
            }
        });
    };

    loadGrid = (VoucherID) => {
        const { dateStart, dateEnd } = this.state;
        const { employee, project } = this.filter;
        const params = {
            ...this.defaultParams,
            ...dateStart,
            ...dateEnd,
            ..._.omit(this.filter, ["employee", "project"]),
            TranMonth: Config.getHRTransMonth(),
            TranYear: Config.getHRTransYear(),
            EmployeeID: _.get(employee, "EmployeeID", ""),
            ProjectID: _.get(project, "ProjectID", ""),
            VoucherID
        };
        this.setState({ gridLoading: true });
        this.props.approvalActions.getGridApprovals(params, (err) => {
            this.setState({ gridLoading: false });
            if (err) return Config.popup.show("INFO", _.get(err, "message", "") || Config.lang("DHR_Loi_chua_xac_dinh"));
        });
    };

    loadCboEmployees = (isReset = false) => {
        const { skip, limit, strSearch } = this.filterCboProjects;
        const param = {
            Type: "EmployeeID",
            ...this.defaultParams,
            skip,
            limit,
            search: strSearch,
        };
        this.setState({ cboEmployeeLoading: true });
        this.props.generalActions.getCboEmployees(param, (err, data) => {
            this.setState({ cboEmployeeLoading: false });
            if (err) {
                Config.popup.show("INFO", _.get(err, "message", "") || Config.lang("DHR_Loi_chua_xac_dinh"));
                return false;
            }
            if (data) {
                const { dataCboEmployees } = this.state;
                const rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total,
                    },
                });
            }
        });
    };

    loadCboProjects = (isReset) => {
        const { skip, limit, strSearch } = this.filterCboProjects;
        const param = {
            HostID: "",
            ...this.defaultParams,
            skip,
            limit,
            search: strSearch,
        };
        this.setState({ cboProjectLoading: true });
        this.props.generalActions.getCboProjects(param, (err, data) => {
            this.setState({ cboProjectLoading: false });
            if (err) return Config.popup.show("INFO", _.get(err, "message", "") || Config.lang("DHR_Loi_chua_xac_dinh"));
            if (data) {
                const { dataCboProjects } = this.state;
                const rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total,
                    },
                });
            }
        });
    };

    loadCboRegisterTypes = () => {
        this.props.approvalActions.getRegisterTypes(err => {
            if (err) {
                Config.popup.show('ERROR', err);
                return false;
            }
        });
    };

    loadCboStatus = () => {
        const params = {
            FormID: "STANDARD",
            Language: Config.language || "84",
        };
        this.props.approvalActions.getCboStatus(params, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboDepartment = () => {
        const param = {
            HostID: "",
            ...this.defaultParams,
        };
        this.props.generalActions.getCboDepartments(param, (err) => {
            if (err) return Config.popup.show("INFO", _.get(err, "message", "") || Config.lang("DHR_Loi_chua_xac_dinh"));
        });
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {getGridApproval} = this.props;
        Config.callChildForm({
            FormID: "W09F2023",
            // ID: voucher_id,
            keyExpr: "VoucherID",
            data: getGridApproval && getGridApproval.rows ? getGridApproval.rows : [],
            onLoad: (params) => this.loadGrid(params && params.ID),
            onAction: (e, data) => {
                const {ID} = e || "";
                this.refs['Approvals'].showPopup(data);
                this.loadFormInfo(ID);
            }
        }, this.props);
    }

    onSelectionChanged = (e) => {
        if (!e) return false;
        const voucherID = _.get(e, "VoucherID", "");
        this.loadFormInfo(voucherID);
    };

    onDrawerClosed = () => {
        this.setState({ VoucherID: "" });
    };

    formatDate = (date) => {
        let dateFormatted = "";
        if (date) dateFormatted = "";
        if (moment(date).isValid()) {
            dateFormatted = moment(date).format("DD/MM/YYYY");
        }
        return dateFormatted;
    };

    getChildPolicyModeName = (type) => {
        switch (type) {
            case "LATE":
                return Config.lang("DHR_Di_tre");
            case "EARLY":
                return Config.lang("DHR_Ve_Som");
            case "OUTEARLY":
                return Config.lang("DHR_Ra_som");
            case "INLATE":
                return Config.lang("DHR_Vao_tre");
            case "LEAVE":
                return Config.lang("DHR_Quy_doi_phep");
            default:
                return "";
        }
    };

    renderFormInfo = (data) => {
        const { classes } = this.props;
        const { expandedMaternity } = this.state;
        const user = Config.getUser({ EmployeeID: _.get(data, "EmployeeID", "") });
        const userPictureUrl = _.get(user, "UserPictureURL", "");
        const userName = _.get(user, "EmployeeName", "");
        return (
            <>
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <LabelText
                                className={classes.labelEmployee}
                                fullWidth={true}
                                label={Config.lang("DHR_Nhan_vien")}
                                allowPadding={true}
                                value={() => (
                                    <div className={"display_row align-center pdb5"} style={{ width: "100%", height: 37 }}>
                                        <div className={classes.divAvatar}>
                                            {userPictureUrl ? (
                                                <Image
                                                    className={classes.imgAvatar}
                                                    src={
                                                        userPictureUrl.indexOf("http") < 0
                                                            ? Config.getCDNPath() + userPictureUrl
                                                            : userPictureUrl
                                                    }
                                                />
                                            ) : (
                                                <InlineSVG
                                                    className={classes.imgAvatar}
                                                    src={require("../../../../assets/images/general/user_default.svg")}
                                                />
                                            )}
                                        </div>
                                        <Input color={"primary"} readOnly={true} value={userName ? userName : ""} disableUnderline={true} />
                                    </div>
                                )}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <Typography className="MuiFormLabel-root" variant="subtitle1">
                                {Config.lang("DHR_Thoi_gian")}
                            </Typography>
                        </Col>
                    </Row>
                </FormGroup>
                <Row>
                    <Col md={6} xs={12} className={"mgb15"}>
                        <LabelText
                            InputProps={{ readOnly: true }}
                            allowPadding={true}
                            label={Config.lang("DHR_Tu")}
                            value={this.formatDate(_.get(data, "AbsentDateFrom", ""))}
                            fullWidth={true}
                        />
                    </Col>
                    <Col md={4} xs={12} className={"mgb15"}>
                        <LabelText
                            InputProps={{ readOnly: true }}
                            allowPadding={true}
                            label={Config.lang("DHR_Den")}
                            value={this.formatDate(_.get(data, "AbsentDateTo", ""))}
                            fullWidth={true}
                        />
                    </Col>
                    <Col md={2} xs={12} className={"mgb15"}>
                        <LabelText
                            InputProps={{ readOnly: true }}
                            allowPadding={true}
                            label={Config.lang("DHR_So_ngay")}
                            value={_.get(data, "TotalMonth", "")}
                            fullWidth={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} xs={12} className={"mgb15"}>
                        <LabelText
                            InputProps={{ readOnly: true }}
                            allowPadding={true}
                            label={Config.lang("DHR_Ngay_du_sinh")}
                            value={this.formatDate(_.get(data, "PlanChildDate", ""))}
                            fullWidth={true}
                        />
                    </Col>
                    <Col md={6} xs={12} className={"mgb15"}>
                        <LabelText
                            InputProps={{ readOnly: true }}
                            allowPadding={true}
                            label={Config.lang("DHR_Ngay_di_lam_du_kien")}
                            value={this.formatDate(_.get(data, "ExpectWorkDay", ""))}
                            fullWidth={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className={"mgb15"}>
                        <LabelText
                            InputProps={{ readOnly: true }}
                            allowPadding={true}
                            label={Config.lang("DHR_Ghi_chu")}
                            value={_.get(data, "NoteU", "")}
                            fullWidth={true}
                        />
                    </Col>
                </Row>
                {Boolean(_.get(data, "ActualWorkDay", "")) ? (
                    <>
                        <FormGroup>
                            <ExpansionPanel expanded={expandedMaternity} style={{ boxShadow: "none" }}>
                                <ExpansionPanelSummary className={"hide"} />
                                <ExpansionPanelDetails style={{ padding: 0 }}>
                                    <div style={{ width: "100%" }}>
                                        <FormGroup>
                                            <Row>
                                                <Col xs={12}>
                                                    <Typography
                                                        className="MuiFormLabel-root"
                                                        style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                                                        variant="subtitle1"
                                                    >
                                                        {Config.lang("DHR_Sau_thai_san:")}
                                                    </Typography>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <Row>
                                            <Col md={6} xs={12} className={"mgb15"}>
                                                <LabelText
                                                    InputProps={{ readOnly: true }}
                                                    allowPadding={true}
                                                    label={Config.lang("DHR_Ngay_di_lam_thuc_te")}
                                                    value={this.formatDate(_.get(data, "ActualWorkDay", ""))}
                                                    fullWidth={true}
                                                />
                                            </Col>
                                            <Col md={6} xs={12} className={"mgb15"}>
                                                <LabelText
                                                    InputProps={{ readOnly: true }}
                                                    allowPadding={true}
                                                    label={Config.lang("DHR_Ngay_sinh_con")}
                                                    value={this.formatDate(_.get(data, "ChildBirthDate", ""))}
                                                    fullWidth={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} xs={12} className={"mgb15"}>
                                                <LabelText
                                                    InputProps={{ readOnly: true }}
                                                    allowPadding={true}
                                                    label={Config.lang("DHR_Che_do_con_nho")}
                                                    value={this.getChildPolicyModeName(_.get(data, "GetChildPolicyType", ""))}
                                                    fullWidth={true}
                                                />
                                            </Col>
                                            <Col md={6} xs={12} className={"mgb15"}>
                                                <LabelText
                                                    InputProps={{ readOnly: true }}
                                                    allowPadding={true}
                                                    label={Config.lang("DHR_Ngay_ket_thuc_che_do")}
                                                    value={this.formatDate(_.get(data, "MaternityEndDate", ""))}
                                                    fullWidth={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} className={"mgb15"}>
                                                <LabelText
                                                    InputProps={{ readOnly: true }}
                                                    allowPadding={true}
                                                    label={Config.lang("DHR_Ghi_chu_sau_thai_san")}
                                                    value={_.get(data, "NoteAfterOff", "")}
                                                    fullWidth={true}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </FormGroup>
                        <div
                            onClick={() => this.setState({ expandedMaternity: !expandedMaternity })}
                            style={{
                                display: "flex",
                                padding: "6px 0",
                                cursor: "pointer",
                                justifyContent: "center",
                                backgroundColor: "#DDDDDD",
                            }}
                        >
                            <i
                                className={
                                    "fas display_row align-center " + (expandedMaternity ? "fa-angle-double-up" : "fa-angle-double-down")
                                }
                                style={{ fontSize: 20 }}
                            />
                            <div className={classes.divText}>
                                <Typography> {expandedMaternity ? Config.lang("DHR_Thu_nho") : Config.lang("DHR_Xem_them")}</Typography>
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
            </>
        );
    };

    handleFilter = (key, e) => {
        const value = _.get(e, "value", _.get(e, "target.value", ""));
        const arrKeyState = ["dateStart.DateFrom", "dateStart.DateTo", "dateEnd.DateFrom2", "dateEnd.DateTo2"];
        if (arrKeyState.includes(key)) {
            const keyState = key.split(".");
            this.setState({
                [keyState[0]]: {
                    ...this.state[keyState[0]],
                    [keyState[1]]: value,
                },
            });
            return;
        }

        this.filter = { ...this.filter, [key]: value };
    };

    renderFilter = () => {
        const { classes, getCboStatus, getCboDepartments, getRegisterTypes } = this.props;
        const { dataCboEmployees, dataCboProjects, dateStart, dateEnd, cboEmployeeLoading, cboProjectLoading } = this.state;
        const { DepartmentID, ApprovalStatus, employee, project } = this.filter;
        return (
            <Filter
                placeholder={Config.lang("DHR_Noi_dung_can_tim_kiem")}
                showHeader={false}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup style={{ marginBottom: "12px" }}>
                                <Row>
                                    <Col md={2} xs={12}>
                                        <label className={classes.labelTextDate} style={{ paddingTop: Config.isMobile ? 0 : "12%" }}>
                                            {Config.lang("DHR_Ngay_bat_dau")}
                                        </label>
                                    </Col>
                                    <Col md={5} xs={12}>
                                        <DateBoxPicker
                                            dateBoxProps={{ max: dateStart.DateTo }}
                                            onValueChanged={(e) => this.handleFilter("dateStart.DateFrom", e)}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            placeholder={Config.lang("DHR_Tu_ngay")}
                                            value={dateStart.DateFrom}
                                        />
                                    </Col>
                                    <Col md={5} xs={12}>
                                        <DateBoxPicker
                                            dateBoxProps={{ min: dateStart.DateFrom }}
                                            onValueChanged={(e) => this.handleFilter("dateStart.DateTo", e)}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            placeholder={Config.lang("DHR_Den_ngay")}
                                            value={dateStart.DateTo}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2} xs={12}>
                                        <label className={classes.labelTextDate} style={{ paddingTop: Config.isMobile ? "3%" : "12%" }}>
                                            {Config.lang("DHR_Ngay_ket_thuc")}
                                        </label>
                                    </Col>
                                    <Col md={5} xs={12}>
                                        <DateBoxPicker
                                            dateBoxProps={{ max: dateEnd.DateTo2 }}
                                            onValueChanged={(e) => this.handleFilter("dateEnd.DateFrom2", e)}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            placeholder={Config.lang("DHR_Tu_ngay")}
                                            value={dateEnd.DateFrom2}
                                        />
                                    </Col>
                                    <Col md={5} xs={12}>
                                        <DateBoxPicker
                                            dateBoxProps={{ min: dateEnd.DateFrom2 }}
                                            onValueChanged={(e) => this.handleFilter("dateEnd.DateTo2", e)}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            placeholder={Config.lang("DHR_Den_ngay")}
                                            value={dateEnd.DateTo2}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12}>
                                        <Combo
                                            label={Config.lang("DHR_Trang_thai_duyet")}
                                            dataSource={getCboStatus}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"ApprovalStatus"}
                                            onValueChanged={(e) => this.handleFilter("ApprovalStatus", e)}
                                            showClearButton={true}
                                            value={ApprovalStatus}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("DHR_Hinh_thuc")}
                                            dataSource={getRegisterTypes}
                                            displayExpr={"RegisterType"}
                                            valueExpr={"IsRegisterType"}
                                            onValueChanged={(e) => this.handleFilter("IsRegisterType", e)}
                                            showClearButton={true}
                                            value={this.filter.IsRegisterType}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={"EmployeeID"}
                                            valueExpr={"EmployeeID"}
                                            value={employee}
                                            loading={cboEmployeeLoading}
                                            stylingMode={"outlined"}
                                            label={Config.lang("DHR_Nhan_vien")}
                                            itemRender={(e) => {
                                                const { data } = e;
                                                if (!data) return null;
                                                return data.EmployeeID + " - " + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={(e) => this.handleFilter("employee", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo
                                            label={Config.lang("DHR_Phong_ban")}
                                            dataSource={Config.storeDataSoureDevExtreme(getCboDepartments)}
                                            valueExpr={"DepartmentID"}
                                            displayExpr={"DepartmentName"}
                                            onValueChanged={(e) => this.handleFilter("DepartmentID", e)}
                                            showClearButton={true}
                                            value={DepartmentID}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboProjects}
                                            skip={this.filterCboProjects.skip}
                                            limit={this.filterCboProjects.limit}
                                            displayExpr={"ProjectName"}
                                            keyExpr={"ProjectID"}
                                            valueExpr={"ProjectID"}
                                            value={project}
                                            loading={cboProjectLoading}
                                            stylingMode={"outlined"}
                                            label={Config.lang("DHR_Du_an")}
                                            itemRender={(e) => {
                                                const { data } = e;
                                                if (!data) return null;
                                                return data.ProjectID + " - " + data.ProjectName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={(e) => this.handleFilter("project", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboProjects.strSearch = e.target.value;
                                                this.filterCboProjects.skip = 0;
                                                this.loadCboProjects(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboProjects.skip = e.skip;
                                                this.filterCboProjects.limit = e.limit;
                                                this.loadCboProjects();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <ButtonGeneral
                                    name={Config.lang("DHR_Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={this.loadGrid}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    render() {
        const { getGridApproval } = this.props;
        const { loading, dataForm, VoucherID, gridLoading } = this.state;
        return (
            <>
                <FormGroup>
                    <ActionToolbar title={Config.lang("DHR_Duyet_nghi_thai_san")} />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <Approvals
                                allowAddingAttachment
                                tableNameAttached={"D09T2262"}
                                FormID={"W09F2023"}
                                disabled={loading}
                                loading={loading}
                                loadingWithDataSource={gridLoading}
                                dataSource={_.get(getGridApproval, "rows", [])}
                                ref={"Approvals"}
                                dataForm={dataForm}
                                selectedRowKey={VoucherID}
                                singleClick
                                filterRender={this.renderFilter}
                                formInfoRender={(data) => this.renderFormInfo(data)}
                                onSelectionChanged={this.onSelectionChanged}
                                onDrawerClosed={this.onDrawerClosed}
                                allowHistoryApproval
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </>
        );
    }

    componentDidMount = () => {
        this.loadGrid();
        this.loadCboEmployees();
        this.loadCboProjects();
        this.loadCboDepartment();
        this.loadCboStatus();
        this.loadCboRegisterTypes();
    };
}
export default compose(
    connect(
        (state) => ({
            getGridApproval: state.approvals.getGridApproval,
            getCboDepartments: state.general.getCboDepartments,
            getCboStatus: state.approvals.getCboStatus,
            getRegisterTypes: state.approvals.getRegisterTypes,
        }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            approvalActions: bindActionCreators(ApprovalActions, dispatch),
        }),
    ),
    withStyles(styles),
)(W09F2023);

/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/24/2020
 * @Example
 */
import React                           from "react";
import { connect }                     from "react-redux";
import * as W09F2030Actions            from "../../../../redux/W0X/W09F2030/W09F2030_actions";
import { bindActionCreators, compose } from "redux";
import Config                          from "../../../../config";
import { Column }                      from 'devextreme-react/data-grid';
import {Row, Col, FormGroup, Image}    from "react-bootstrap"
import GridContainer                   from "../../../grid-container/grid-container";
import ActionToolbar                   from "../../../common/toolbar/action-toolbar";
import ButtonGeneral                   from "../../../common/button/button-general";
import withStyles                      from "@material-ui/core/styles/withStyles";
import IconButton                      from "@material-ui/core/IconButton";
import Divider                         from "@material-ui/core/Divider";
import Modal                           from "../../../common/modal/modal";
import * as generalActions    from "../../../../redux/general/general_actions";
import _                      from 'lodash';
import moment                 from 'moment';
import { FormLabel as Label } from "@material-ui/core";
import { Combo }        from "../../../common/form-material";
import Filter           from '../../../filter/filter';
import DateBoxPicker    from "../../../common/form-material/date-box";
import UserImage        from "../../../common/user/user-image";
import UserName         from "../../../common/user/user-name";
import Status           from "../../../common/status/status";
import Icons            from "../../../common/icons/";
import W09F2032Popup    from "./W09F2032Popup";
import {browserHistory} from "react-router";

const styles = {
    divAvatar: {
        width: 46,
        height: 46,
        minWidth: 46,
        minHeight: 46,
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: 8
    },
    divText: {
        fontWeight: 500,
    },
    dateInfo: {
        display: "flex",
        alignItems: "center"
    },
};
class W09F2030 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            mode: "add",
            rowData: null,
            showW09F2032Popup: false,
            dataGrid: [],
            total: 0,
            gridLoading: false,
            employeeLoading: false,
            filterLoading: false,
            EmployeeID: null,
            DateFrom: null,
            DateTo: null,

        };
        this.filter = {
            EmployeeID: null,
            DepartmentID: "",
            AppStatusID: "",
            skip: 0,
            limit: 10,
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 20
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F2030", (isPer) => {
            this.setState({ iPermission: isPer });
        });
    };

    loadCboEmployees = () => {
        const params = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W09F2030",
            Language: Config.language || '84',
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setState({ employeeLoading: true });
        this.props.generalActions.getCboEmployees(params, error => {
            this.setState({ employeeLoading: false });
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    };

    loadCboAppStatus = () => {
        const params = {
            FormID: "STANDARD",
            Language: Config.language || 84
        };
        this.props.generalActions.getCboAppStatus(params, error => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    loadCboDepartments = () => {
        const params = {
            HostID: "",
            FormID: "W09F2030",
            Language: Config.language || "84",
            DivisionID: Config.getDivisionID(),
        };
        this.props.generalActions.getCboDepartments(params, error => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    loadGrid = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const { AppStatusID, DepartmentID, skip, limit } = this.filter;
        const { DateFrom, DateTo, EmployeeID } = this.state;
        let params = {
            TransID: VoucherID || "",
            FormID: "W09F2030",
            Language: Config.language || "84",
            DateFrom: DateFrom || null,
            DateTo: DateTo || null,
            AppStatusID,
            EmployeeID: EmployeeID && EmployeeID.EmployeeID ? EmployeeID.EmployeeID : "",
            DepartmentID,
            skip,
            limit
        };
        this.setState({ gridLoading: true });
        this.props.w09F2030Actions.getDataGrid(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({ gridLoading: false });
                return false;
            }
            this.setState({
                dataGrid: data && data.rows ? data.rows : [],
                total: data && data.total ? data.total : 0,
            }, () => {
                this.setState({ gridLoading: false });
            });
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadGrid();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {dataGrid} = this.state;
        Config.callChildForm({
            FormID: "W09F2030",
            // ID: voucher_id,
            keyExpr: "ProTransID",
            data: dataGrid || [],
            onLoad: (params) => this.loadGrid(params && params.ID),
            onAction: (e, data) => this.onView({ row: { data: data } })
        }, this.props);
    }

    onLoadDataFilter = () => {
        this.loadCboEmployees();
        this.loadCboAppStatus();
        this.loadCboDepartments();
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadGrid();
    };

    onAdd = () => {
        this.setState({
            mode: "add",
            rowData: null,
            showW09F2032Popup: true
        });
    };

    onCloseModal = (isSaved) => {
        if(isSaved) this.loadGrid();
        this.setState({
            showW09F2032Popup: false
        });
    };

    onView = (e) => {
        const { data } = e.row;
        this.setState({
            mode: "edit",
            rowData: data,
            showW09F2032Popup: true
        });
    };

    onDelete = (e) => {
        const { data } = e.row;
        if (data && data.ProTransID) {
            Config.popup.show("YES_NO", Config.lang("DHR_Ban_co_chac_muon_xoa?"), () => {
                let params = {
                    ProTransID: _.get(data, "ProTransID", ""),
                };
                this.props.w09F2030Actions.deleteData(params, (errors) => {
                    if (errors) {
                        let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        return false;
                    }
                    this.loadGrid();
                    Config.notify.show('success', Config.lang("DHR_Xoa_thanh_cong"), 2000);
                });
            });
        } else {
            Config.popup.show('INFO', Config.lang("DHR_Loi_chua_xac_dinh"));
        }
    };


    filterChange = (key, data) => {
        if (!key) return false;
        const value = _.get(data, "value", "");
        const stateFieldName = ['DateFrom', 'DateTo', 'EmployeeID'];
        if (stateFieldName.includes(key)) {
            this.setState({ [key]: value });
            return;
        }
        this.filter[key] = value;

    };

    onChangePageProfile = data => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: {EmployeeID: data.EmployeeID}
        });
    };

    renderFilters = () => {
        const { EmployeeID ,employeeLoading, DateFrom, DateTo } = this.state;
        const { getCboAppStatus, getCboEmployees, getCboDepartments } = this.props;
        const { AppStatusID, DepartmentID} = this.filter;
        return (
            <Filter
                placeholder={Config.lang("DHR_Noi_dung_can_tim")}
                onOpenLoaded={this.onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <div className={"display_row align-center"}>
                                        <Col xs={12} sm={2}>
                                            <Label style={{fontWeight:700, color:"#222B45"}}>{Config.lang("DHR_Ngay")}</Label>
                                        </Col>
                                        <Col xs={12} sm={5}>
                                            <div className={"display_row align-center"}>
                                                <DateBoxPicker
                                                    placeholder={Config.lang("DHR_Tu_ngay")}
                                                    max={DateTo}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    width={"100%"}
                                                    value={DateFrom}
                                                    onValueChanged={e => this.filterChange("DateFrom", e)}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={5}>
                                            <div className={"display_row align-center"}>
                                                <DateBoxPicker
                                                    min={DateFrom}
                                                    placeholder={Config.lang("DHR_Den_ngay")}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    width={"100%"}
                                                    value={DateTo}
                                                    onValueChanged={e => this.filterChange("DateTo", e)}
                                                />
                                            </div>
                                        </Col>
                                    </div>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo
                                            label={Config.lang("DHR_Trang_thai_duyet")}
                                            dataSource={getCboAppStatus}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"AppStatusID"}
                                            onValueChanged={e => this.filterChange("AppStatusID", e)}
                                            showClearButton={true}
                                            value={AppStatusID}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} >
                                        <Combo.LoadMore
                                            dataSource={getCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={'EmployeeID'}
                                            valueExpr={'EmployeeID'}
                                            value={EmployeeID}
                                            loading={employeeLoading}
                                            stylingMode={'outlined'}
                                            label={Config.lang('DHR_Nhan_vien')}
                                            itemRender={(e) => {
                                                const { data } = e;
                                                if (!data) return null;
                                                return data.EmployeeID + ' - ' + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            onValueChanged={e => this.filterChange("EmployeeID", e)}
                                            onInputChanged={(e) => {
                                                if (this.filterCboEmployees.timer) clearTimeout(this.filterCboEmployees.timer);
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.timer = setTimeout(() => {
                                                    this.filterCboEmployees.skip = 0;
                                                    this.filterCboEmployees.limit = 20;
                                                    this.loadCboEmployees(true);
                                                }, 700);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo
                                            label={Config.lang("DHR_Phong_ban")}
                                            dataSource={Config.storeDataSoureDevExtreme(getCboDepartments)}
                                            valueExpr={"DepartmentID"}
                                            displayExpr={"DepartmentName"}
                                            onValueChanged={e => this.filterChange("DepartmentID", e)}
                                            showClearButton={true}
                                            value={DepartmentID || ""}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral
                                    name={Config.lang("DHR_Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={this.loadGrid}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    renderEmpProfile = e => {
        const {classes} = this.props;
        const {data} = e.row;
        const date = moment(data.ProposalDate).format("LT, DD/MM/YYYY");
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <div className={classes.divAvatar}>
                    <UserImage data={data} onClick={() => this.onChangePageProfile(data)}/>
                </div>
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}>
                        <UserName data={data}/>
                    </div>
                    <div className={classes.dateInfo}>
                        <Image
                            src={require("../../../../assets/images/icon-calendar.svg")}
                        />
                        <div style={{paddingLeft: "8px"}} className={"date-text-info"}>
                            {data.ProposalDate && date}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderInfo = e => {
        const {data} = e.row;
        const date = data?.ValidDate && moment(data.ValidDate).format("DD/MM/YYYY");
        const {classes} = this.props;
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                <div>
                    {Config.lang("DHR_Ngay_hieu_luc")}:{" "}
                    {date || ""}
                </div>
                {data.ReasonName && (
                    <div>
                        {Config.lang("DHR_Ly_do")}: {data.ReasonName || ""}
                    </div>
                )}
                <div>
                    {Config.lang("DHR_Nguoi_lap")}: {data.ProposerName || ""}
                </div>
                {data.ApproverName && (
                    <div>
                        {Config.lang("DHR_Nguoi_duyet")}: {data.ApproverName || ""} {" "}
                    </div>
                )}
                {data.ApprovalNote &&
                    <div>
                        {Config.lang("DHR_Ghi_chu_duyet")}: {data.ApprovalNote || ""}
                    </div>
                }
            </div>
        );
    };

    renderStatus = (e) => {
        const { data } = e.row;
        return (
            <Status data={data} />
        );
    };

    renderAction = e => {
        const {data} = e.row;
        const {iPermission} = this.state;
        return (
            <div className={"display_row align-center"}>
                <IconButton
                    aria-label="view"
                    size="small"
                    disabled={!(iPermission > 0)}
                    onClick={() => this.onView(e)}
                >
                    <Icons name={"view"}/>
                </IconButton>
                <Divider
                    orientation="vertical"
                    style={{width: 2, height: 11, margin: "0 5px"}}
                />
                <IconButton
                    disabled={!(iPermission > 3)  ||  (data && Number(data.AppStatusID) !== 0) }
                    aria-label="delete"
                    size="small"
                    onClick={() => this.onDelete(e)}
                >
                    <Icons name={"delete"}/>
                </IconButton>
            </div>
        );
    };

    render() {
        let { iPermission, edit, showW09F2032Popup, dataGrid, mode, rowData, gridLoading, total } = this.state;
        if (!iPermission) return null;
        return (
            <React.Fragment>
                <Modal open={showW09F2032Popup} maxWidth={"md"} fullWidth={true}
                       title={Config.lang("DHR_Dieu_chinh_luong")}
                       onClose={() => this.onCloseModal(false)}>
                    <W09F2032Popup edit={edit} mode={mode} data={rowData} onClose={this.onCloseModal} />
                </Modal>
                <div className={"hidden"}>{this.renderFilters()}</div>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("DHR_De_xuat_dieu_chinh_luong")}
                    >
                        <ButtonGeneral name={Config.lang('DHR_Them')}
                                       disabled={gridLoading || !(iPermission > 1)}
                                       typeButton={'add'}
                                       style={{ textTransform: 'uppercase' }}
                                       size={"large"}
                                       onClick={this.onAdd} />
                    </ActionToolbar>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <GridContainer
                                style={{ border: 'none' }}
                                dataSource={dataGrid}
                                totalItems={total}
                                itemPerPage={this.filter.limit}
                                skipPerPage={this.filter.skip}
                                height={Config.getHeightGrid()}
                                typePaging={"remote"}
                                loading={gridLoading}
                                rowAlternationEnabled={false}
                                showRowLines={true}
                                showBorders={false}
                                showColumnLines={false}
                                showColumnHeaders={false}
                                noDataText={Config.lang("DHR_No_data")}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                            >
                                <Column cellRender={this.renderEmpProfile} width={250}/>
                                <Column cellRender={this.renderInfo}/>
                                <Column cellRender={this.renderStatus} alignment={"center"} width={200}/>
                                <Column cellRender={this.renderAction} width={200}/>
                            </GridContainer>
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        )
    }
}

export default compose(connect(state => ({
    getCboAppStatus: state.general.getCboAppStatus,
    getCboEmployees: state.general.getCboEmployees,
    getCboDepartments: state.general.getCboDepartments,
}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    w09F2030Actions: bindActionCreators(W09F2030Actions, dispatch)
})), withStyles(styles))(W09F2030);

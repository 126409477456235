/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 2/7/2020
 * @update 2/13/2020
 * @Example
 */

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as W09F2060Actions from "../../../../redux/W0X/W09F2060/W09F2060_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { Row, Col, FormGroup } from "react-bootstrap";
import ButtonGeneral from "../../../common/button/button-general";
import withStyles from "@material-ui/core/styles/withStyles";
import { Combo, TextField } from "../../../common/form-material";
import * as generalActions from "../../../../redux/general/general_actions";
import DateBoxPicker from "../../../common/form-material/date-box";
import Attachments from "../../../common/attachments/attachments";
import IconButton from "@material-ui/core/IconButton";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Modal from "../../../common/modal/modal";
import NumberFormat from "react-number-format";

const styles = {
  avatar: {
    width: 30,
    height: 30,
    borderRadius: "50%",
    border: "1px solid #ddd",
    overflow: "hidden",
    marginRight: 8
  },
  imgAvatar: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain"
  },
  chip: {
    fontSize: "1rem",
    fontWeight: 400,
    border: "none",
    backgroundColor: "#EBEBEB"
  }
};
class W09F2060Popup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showW09F2060Popup: false,
      dataGrid: [],
      dataCboProjects: {
        rows: [],
        total: 0
      },
      employee: null,
      project: null,
      departmentID: "",
      proDisRewardFormID: "",
      proDisRewardLevelID: "",
      proContent: "",
      proNotice: "",
      disabled: false,
      proCoefficients: this.convertProCoefficients(),
      error: {},
      loading: false,
      uploading: false,
      helperTextQuantity: "",
      ValidDate: null,
      dataOldAttachments: [],
      errorValidDate: null,
      errorEmployee: null,
      disabledEmp : false,

      projectLoading: false,
    };
    this.attachments = [];
    this.deletedFile = [];
    this.filterCboProjects = {
      timer: null,
      strSearch: "",
      skip: 0,
      limit: 50
    };
  }

  loadCboDisRewardForm = () => {
    this.props.w09f2060Actions.loadCboDisRewardForm(error => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
    });
  };

  loadCboProDisRewardLevel = () => {
    this.props.w09f2060Actions.loadCboProDisRewardLevel(error => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
    });
  };

  loadCboDepartment = () => {
    const param = {
      HostID: "",
      FormID: "W09F2060",
      DivisionID: Config.getDivisionID(),
      Language: Config.language || "84"
    };

    this.props.generalActions.getCboDepartments(param, error => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
    });
  };

  loadCboProjects = (isReset) => {
    const param = {
      HostID: "",
      FormID: "W09F2060",
      Language: Config.language || "84",
      skip: this.filterCboProjects.skip,
      limit: this.filterCboProjects.limit,
      search: this.filterCboProjects.strSearch
    };
    this.setState({projectLoading: true});
    this.props.generalActions.getCboProjects(param, (error, data) => {
      this.setState({projectLoading: false});
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
      if (data) {
        const {dataCboProjects} = this.state;
        const rows = data && data.rows ? data.rows : data;
        const total = data && data.total ? data.total : data.length;
        this.setState({
          dataCboProjects: {
            rows: isReset ? rows : dataCboProjects.rows.concat(rows),
            total: total
          }
        })
      }
    });
  };

  loadForm = () => {
    const { type, data } = this.props;
    const param = {
      ProTransID: data && data.ProTransID ? data.ProTransID : "",
      Mode: type === 1 ? "department" : (type === 2 ? "project" : "employee"),
      Language: Config.language || "84"
    };
    this.setState({ loading: true });
    this.props.w09f2060Actions.loadForm(param, (error, data) => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
      if (data) {
        const proCoefficients = this.convertProCoefficients(data);
        const user = Config.getUser({EmployeeID: data.EmployeeID});
        this.setState({
          employee: user ? user : data,
          project: {ProjectID: data.ProjectID ? data.ProjectID: "", ProjectName: data.ProjectName ? data.ProjectName: (data.ProjectID ? data.ProjectID: "") },
          departmentID: data.DepartmentID ? data.DepartmentID : "",
          proDisRewardFormID: data.ProDisRewardFormID,
          proDisRewardLevelID: data.ProDisRewardLevelID,
          proContent: data.ProContent,
          proNotice: data.ProNotice,
          proCoefficients,
          ValidDate: data.ValidDate
        });
      }
      this.setState({ loading: false });
    });
  };

  convertProCoefficients = data => {
    const { getRewardCaption } = this.props;
    const proCoefficientArr = {};
    if (getRewardCaption) {
      getRewardCaption.forEach(item => {
        proCoefficientArr[item.Code] = data ? data[item.Code] : 0;
      });
    }
    return proCoefficientArr;
  };

  handleChange = (key, e, i = "", code) => {
    if (!key) return false;
    switch (key) {
      case "ProDisRewardFormID": {
        this.setState({ proDisRewardFormID: e.value });
        break;
      }
      case "ProDisRewardLevelID": {
        this.setState({ proDisRewardLevelID: e.value });
        break;
      }
      case "ProCoefficient": {
        this.setState({
          proCoefficients: {
            ...this.state.proCoefficients,
            [code]: e.target.value ? Math.abs(Number(e.target.value)) : e.target.value
          }
        });
        break;
      }
      case "ProContent": {
        this.setState({ proContent: e.target.value });
        break;
      }
      case "ProNotice": {
        this.setState({ proNotice: e.target.value });
        break;
      }
      case "EmployeeID": {
        this.setState({ employee: e.value });
        break;
      }
      case "ProjectID": {
        this.setState({ project: e.value });
        break;
      }
      case "DepartmentID": {
        this.setState({ departmentID: e.value });
        break;
      }
      case "ValidDate": {
        this.setState({ ValidDate: e.value });
        break;
      }
      default:
        break;
    }
  };

  componentDidMount() {
    const { mode, data, type } = this.props;
    this.loadCboDisRewardForm();
    this.loadCboProDisRewardLevel();
    switch (type) {
      case 1:
        this.loadCboDepartment();
        break;
      case 2:
        this.loadCboProjects();
        break;
      default:
        break;
    }
    if (mode === "view") {
      this.setState({
        disabledEmp: true,
      });
      this.loadForm();
      this.loadAttachments();
        if (parseInt(data.AppStatusID) !== null || parseInt(data.AppStatusID) !== 4) {
          this.setState({ disabled: true });
        }
        if(parseInt(data.AppStatusID) === 0){
          this.setState({ disabled: false });
        }
    }
  }

  setStateErrorText(objValue, key) {
    const { errorHelperText } = this.state;
    const stateError = key ? key : "error";
    this.setState({
      [stateError]: {
        ...errorHelperText,
        ...objValue
      }
    });
    return Object.keys(objValue).length !== 0;
  }

  onStore = () => {
    const { mode, type, data } = this.props;
    const isEdit = mode === "view" || false;
    const {
      proContent,
      proNotice,
      employee,
      project,
      departmentID,
      proDisRewardFormID,
      proDisRewardLevelID,
      ValidDate
    } = this.state;

    const arrAttachments = this._getAttachments();
    let params = {
      Mode: type === 1 ? "department" : (type === 2 ? "project" : "employee"),
      EmployeeID: employee && employee.EmployeeID ? employee.EmployeeID : "",
      ProjectID: project && project.ProjectID ? project.ProjectID : "",
      DepartmentID: departmentID,
      HREmployeeID: Config.getHREmployeeID(),
      ProTransID: data && data.ProTransID ? data.ProTransID : "",
      ProposalDate: data && data.ProposalDate ? data.ProposalDate : "",
      ProContent: proContent,
      ProNotice: proNotice,
      ProDisRewardFormID: proDisRewardFormID,
      ProDisRewardLevelID: proDisRewardLevelID,
      ProTeamID: "",
      ProDepartmentID: "",
      ProAuthority: "",
      ProResponsibility: "",
      ProDivisionID: "",
      ProStatus: 0,
      // ValidDate: isEdit ? "" : null,
      ValidDate: ValidDate,
      Language: Config.language || "84",
      DivisionID: Config.getDivisionID(),
      TranMonth: Config.getHRTransMonth(),
      TranYear: Config.getHRTransYear(),
      ...this.state.proCoefficients,
      arrAttachment: JSON.stringify(arrAttachments)
    };

    if (!ValidDate) {
      this.setState({errorValidDate: Config.lang("Truong_nay_bat_buoc_nhap")});
      return false;
    }
    switch (type) {
      case 0:
        const employeeID = employee && employee.EmployeeID ? employee.EmployeeID : "";
        if (!employeeID) {
          this.setState({errorEmployee: Config.lang("Truong_nay_bat_buoc_nhap")});
          return false;
        }
        break;
      case 1:
          if (!departmentID) {
              this.setState({errorDepartment: Config.lang("Truong_nay_bat_buoc_nhap")});
              return false;
          }
        break;
      case 2:
          const projectID = project && project.ProjectID ? project.ProjectID : "";
          if (!projectID) {
              this.setState({errorProject: Config.lang("Truong_nay_bat_buoc_nhap")});
              return false;
          }
        break;
      default: break;
    }
    this.setState({ loading: true });
    this.props.w09f2060Actions[isEdit ? "edit" : "save"](
      params,
      (error, data) => {
        if (error) {
          this.props.onClose();
          let message = Config.lang("DHR_Loi_chua_xac_dinh");
          switch (error.code) {
            case "F2000E002":
              message = "Mode " + Config.lang("DHR_Bat_buoc");
              break;
            case "F2000E003":
              message = "LeaveTypeID " + Config.lang("DHR_Bat_buoc");
              break;
            case "F2000E004":
              message = "IsRegisterType " + Config.lang("DHR_Bat_buoc");
              break;
            case "F2000E001":
              message = Config.lang("DHR_Luu_khong_thanh_cong");
              break;
            default:
              break;
          }
          Config.popup.show("INFO", message);
          return false;
        }
        Config.notify.show('success', Config.lang("DHR_Luu_thanh_cong"), 2000);
        this.props.onClose(true);
      }
    );
  };

  onAttachment = () => {
    if (this.attRef) {
      this.attRef.onAttachment();
    }
  };

  onUploading = value => {
    this.setState({ uploading: value });
  };

  _getAttachments = () => {
    const listAttachments = Config.helpers.getFileInfomations(this.attachments);
    const { dataOldAttachments } = this.state;

    let arrAttachment = [];
    listAttachments.forEach(att => {
      arrAttachment.push({
        URL: att.url ? att.url : "",
        FileName: att.fileName ? att.fileName : "",
        FileSize: att.fileSize ? att.fileSize : "",
        FileExt: att.fileExt ? att.fileExt : ""
      });
    });
    if (dataOldAttachments && dataOldAttachments.length > 0) {
      arrAttachment = dataOldAttachments.concat(arrAttachment);
    }

    return arrAttachment;
  };

  loadAttachments = () => {
    const { data } = this.props;
    const param = {
      KeyID: data && data.ProTransID ? data.ProTransID : ""
    };
    this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
      if (data && data.length > 0) {
        this.setState({
          dataOldAttachments: data,
          loading:false,
        });
      }
      this.setState({
        loading:false,
      });
    });
  };

  onChangeAttachments = (e) => {
    this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
    if (e.deletedFiles && e.deletedFiles.length > 0) {
      this.deletedFile = [ ...e.deletedFiles];
      const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
      this.setState(prevState => ({
        dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
          return _arrRemove.indexOf(att.AttachmentID) < 0;
        })
      }));
    }
  };

  render() {
    let {
      type,
      getCboProDisRewardLevel,
      getCboDisRewardForm,
      getRewardCaption,
      getCboDepartments,
      data
    } = this.props;
    const {
      dataCboProjects,
      uploading,
      proDisRewardFormID,
      proDisRewardLevelID,
      proContent,
      proNotice,
      proCoefficients,
      error,
      loading,
      employee,
      departmentID,
      project,
      ValidDate,
      dataOldAttachments,
      disabled,
      errorValidDate,
      errorEmployee,
      errorDepartment,
      errorProject,
      disabledEmp,
      projectLoading,
    } = this.state;
    return (
      <>
        <Modal.Content>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
                {type === 0 && <Combo.Employees
                    acceptCustomValue={true}
                    error={errorEmployee}
                    displayExpr={'EmployeeName'}
                    keyExpr={'EmployeeID'}
                    valueExpr={'EmployeeID'}
                    subTextExpr={"EmployeeID"}
                    value={employee}
                    FormID={"W09F2060"}
                    stylingMode={'underlined'}
                    shrink={true}
                    required={true}
                    disabled={loading || disabledEmp}
                    label={Config.lang('DHR_Nhan_vien')}
                    dropdownProps={{
                        readOnly: disabled
                    }}
                    onValueChanged={e => this.handleChange("EmployeeID", e)}
                />}
                {type === 1 && <Combo
                    acceptCustomValue={true}
                    error={errorDepartment}
                    dataSource={Config.storeDataSoureDevExtreme(getCboDepartments)}
                    displayExpr={'DepartmentName'}
                    valueExpr={"DepartmentID"}
                    value={departmentID}
                    FormID={"W09F2060"}
                    stylingMode={'underlined'}
                    shrink={true}
                    required={true}
                    margin={"normal"}
                    disabled={loading || disabled || disabledEmp}
                    label={Config.lang('DHR_Phong_ban')}
                    dropdownProps={{
                        readOnly: disabled
                    }}
                    onValueChanged={e => this.handleChange("DepartmentID", e)}
                />}
              {type === 2 && <Combo.LoadMore
                  error={errorProject}
                  dataSource={dataCboProjects}
                  skip={this.filterCboProjects.skip}
                  limit={this.filterCboProjects.limit}
                  displayExpr={'ProjectName'}
                  keyExpr={'ProjectID'}
                  valueExpr={'ProjectID'}
                  value={project ? project : null}
                  loading={projectLoading}
                  stylingMode={'underlined'}
                  margin={"normal"}
                  shrink={true}
                  disabled={loading || disabled || disabledEmp}
                  required={true}
                  label={Config.lang('DHR_Du_an')}
                  itemRender={(e) => {
                    const {data} = e;
                    if (!data) return null;
                    return data.ProjectID + ' - ' + data.ProjectName;
                  }}
                  showClearButton={true}
                  // showCheckBoxMode={"none"}
                  onValueChanged={e => this.handleChange("ProjectID", e)}
                  onInputChanged={(e) => {
                    this.filterCboProjects.strSearch = e.target.value;
                    this.filterCboProjects.skip = 0;
                    this.loadCboProjects(true);
                  }}
                  onLoadMore={(e) => {
                    this.filterCboProjects.skip = e.skip;
                    this.filterCboProjects.limit = e.limit;
                    this.loadCboProjects();
                  }}
              />}
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <DateBoxPicker
                height={28}
                useMaskBehavior={true}
                label={Config.lang("DHR_Ngay_hieu_luc")}
                width={"100%"}
                stylingMode={"underlined"}
                shrink={true}
                InputLabelProps={{ style: { marginTop: "-7px" } }}
                value={ValidDate}
                onValueChanged={e => this.handleChange("ValidDate", e)}
                margin={"normal"}
                disabled={loading || disabled}
                error={errorValidDate}
                required={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Combo
                error={error && error["ProDisRewardFormID"]}
                dataSource={getCboDisRewardForm}
                displayExpr={"ProDisRewardFormName"}
                valueExpr={"ProDisRewardFormID"}
                subTextExpr={"ProDisRewardFormID"}
                stylingMode={"underlined"}
                disabled={loading || disabled}
                searchEnabled={true}
                shrink={true}
                value={proDisRewardFormID}
                label={Config.lang("DHR_Hinh_thuc_khen_thuong")}
                onValueChanged={e => this.handleChange("ProDisRewardFormID", e)}
                margin={"normal"}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Combo
                error={error && error["ProDisRewardLevelID"]}
                dataSource={getCboProDisRewardLevel}
                displayExpr={"ProDisRewardLevelName"}
                valueExpr={"ProDisRewardLevelID"}
                subTextExpr={"ProDisRewardLevelID"}
                stylingMode={"underlined"}
                disabled={loading || disabled}
                searchEnabled={true}
                shrink={true}
                value={proDisRewardLevelID}
                label={Config.lang("DHR_Cap_khen_thuong")}
                onValueChanged={e =>
                  this.handleChange("ProDisRewardLevelID", e)
                }
                margin={"normal"}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <TextField
                error={error && error["ProContent"]}
                label={Config.lang("DHR_Noi_dung")}
                variant={"standard"}
                margin={"normal"}
                value={proContent}
                InputLabelProps={{
                  shrink: true
                }}
                disabled={loading || disabled}
                helperText={this.state.helperTextQuantity}
                onChange={e => this.handleChange("ProContent", e)}
                fullWidth
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <TextField
                error={error && error["ProNotice"]}
                label={Config.lang("DHR_Ghi_chu")}
                variant={"standard"}
                margin={"normal"}
                value={proNotice}
                disabled={loading || disabled}
                InputLabelProps={{
                  shrink: true
                }}
                helperText={this.state.helperTextQuantity}
                onChange={e => this.handleChange("ProNotice", e)}
                fullWidth
              />
            </Col>
          </Row>
          <Row>
            {getRewardCaption &&
              getRewardCaption.map((item, i) => (
                <Col key={i} xs={12} sm={12} md={6} lg={6}>
                  {
                    getRewardCaption[i].Disabled === 0 &&
                      <NumberFormat
                          error={error && error["ProCoefficient"]}
                          label={item.Short}
                          customInput={TextField}
                          thousandSeparator={true}
                          value={String(proCoefficients[item.Code]) || ""}
                          onValueChange={e => this.handleChange("ProCoefficient", {target:e}, i, item.Code)}
                          fullWidth
                          disabled={loading || disabled}
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant={"standard"}
                          margin={"normal"}
                          helperText={this.state.helperTextQuantity}
                          InputProps={{ inputProps: { min: 0 } }}
                      />
                  }
                </Col>
              ))}
          </Row>
          <FormGroup style={{ marginBottom: 34 }}>
            <Attachments
              ref={ref => this.attRef = ref}
              showButton={true}
              files={dataOldAttachments}
              disabled={disabled || loading || uploading}
              maxLength={5}
              uploading={loading}
              onUploading={this.onUploading}
              onChanged={this.onChangeAttachments}
            />
          </FormGroup>
        </Modal.Content>
        <Modal.Actions style={{ justifyContent: "space-between" }}>
              <div>
                <IconButton
                    aria-label="view"
                    disabled={loading || disabled || uploading}
                    onClick={this.onAttachment}
                >
                  <AttachFileIcon />
                </IconButton>
              </div>
              <div>
                <ButtonGeneral
                    name={Config.lang("DHR_Luu")}
                    typeButton={"save"}
                    disabled={Number(data?.OnlyWatch) === 1 || loading || disabled || uploading}
                    style={{ textTransform: "uppercase" }}
                    size={"large"}
                    onClick={this.onStore}
                />
              </div>
        </Modal.Actions>
      </>
    );
  }
}

W09F2060Popup.defaultProps = {
  type: 0
};
W09F2060Popup.propTypes = {
  open: PropTypes.bool,
  mode: PropTypes.string,
  type: PropTypes.oneOf([0, 1, 2]), //0: Employee, 1: Department, 2: Projects.
  data: PropTypes.any,

  onClose: PropTypes.func
};

export default compose(
  connect(
    state => ({
      getCboEmployees: state.general.getCboEmployees,
      getCboDepartments: state.general.getCboDepartments,
      getLeaveTypes: state.W09F2060.getLeaveTypes,
      loadForm: state.W09F2060.loadForm,
      getCboDisRewardForm: state.W09F2060.getCboDisRewardForm,
      getCboProDisRewardLevel: state.W09F2060.getCboProDisRewardLevel,
      getRewardCaption: state.W09F2060.getRewardCaption,
      getForm: state.W09F2060.getForm,
      getAttachmentsByTransID: state.general.getAttachmentsByTransID
    }),
    dispatch => ({
      w09f2060Actions: bindActionCreators(W09F2060Actions, dispatch),
      generalActions: bindActionCreators(generalActions, dispatch)
    })
  ),
  withStyles(styles)
)(W09F2060Popup);
